import React from "react";
import styled from "styled-components";
import { LoopingRhombusesSpinner } from 'react-epic-spinners'

const wrapper_padding_w = 200;
const wrapper_padding_h = 40;

const Wrapper = styled.div`
    position: absolute;
    width: calc(100% + ${wrapper_padding_w}px);
    height: calc(100% + ${wrapper_padding_h}px);
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(12px);
    background: rgba(#fff,.1);
    z-index: 2;
    top: -${wrapper_padding_h / 2}px;
    left: -${wrapper_padding_w / 2}px;
`;


const LoadingOverlay = (props) => {
    return (
        <Wrapper>
            <LoopingRhombusesSpinner color="#333333" />
        </Wrapper>
    )
}

export default LoadingOverlay;