import React, { useEffect, useState, useMemo } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "components/templates/layout";
import SearchResult from "components/organisms/searchResult";
import SEO from "components/seo";
import LoadingOverlay from "src/components/molecules/loadingOverlay.js";

const SearchTitle = styled.div`
    display: block;
    color: inherit;
    font-weight: bold;
    margin-bottom: 3rem;
    font-size: 30px;
`;

const TitletoPostMap = (edges) => {
    const map = {}
    for (let edge of edges) {
        const node = edge.node;
        map[node.frontmatter.title] = node;
    }
    return map;
}

const SearchPage = ({ location, data, pageContext }) => {
    const { title, pageListSize } = data.site.siteMetadata;
    const postTitleMap = TitletoPostMap(data.allMarkdownRemark.edges);
    const params = new URLSearchParams(location.search);
    const search_query = params.get("q");

    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState({});

    const [posts, setPosts] = useState([])
    useEffect(() => {
        postSearchQuery(search_query).then(search_result => {
            if (search_result.error) {
                setFetchError(search_result);
                return
            }
            const docs = search_result.reduce((acc, res) => {
                const doc = postTitleMap[res.doc.title];
                if (doc) {
                    doc["score"] = res.score;
                    doc["answer"] = res.answer;
                    acc.push(
                        { node: doc }
                    )
                };
                return acc;
            }, [])
            setPosts(docs);
            setFetchError({});
        });
    }, [location.search]);

    const postSearchQuery = async (query) => {
        setIsLoading(true);
        const url = "https://search.argonism.info/search";
        const request_body = {
            "query": query
        }
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify(request_body)
            })
            if (!response.ok) {
                const err = new Error(response.statusText);
                err.number = response.status;
                throw err;
            }
            return response.json();
        }
        catch (err) {
            return {
                error: true,
                msg: "Sorry, search feature is unavailable now. Try it later please. You might get result for some query because of server cache.",
                num: err.number,
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <Layout location={location} title={title}>
            <SEO title="Search" />
            <SearchTitle>Search Results for: {search_query}</SearchTitle>
            <div style={{ position: "relative" }}>
                {isLoading && <LoadingOverlay></LoadingOverlay>}
                {/* <LoadingOverlay></LoadingOverlay> */}
                {fetchError.error ?
                    <div>
                        <h1>{"😮 whoops, something went wrong."}</h1>
                        <div>{fetchError.msg}</div>
                    </div>
                    : <SearchResult
                        data={posts}
                        page={pageContext}
                        path={"/search"}
                        pageListSize={pageListSize}
                    />
                }
            </div>
        </Layout>
    );
}

export default SearchPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                pageListSize
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { draft: { eq: false } } }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 300, truncate: true)
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "YYYY-MM-DD")
                        title
                        category
                        draft
                        cover {
                            childImageSharp {
                                fixed(width: 120, height: 120) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
