import React from "react";
import SearchResultItem from "../molecules/searchResultItem";

const SearchResult = ({ data, page, path, pageListSize }) => {
    const posts = data.filter((post) => {
        if (!post) { return false }
        return !post.node.frontmatter.draft;
    });
    return (
        <div>
            {posts.length > 0
                ? posts.map(({ node }) => {
                    return <SearchResultItem key={node.fields.slug} node={node} />;
                })
                : <p>No Result</p>
            }
        </div>
    );
};

export default SearchResult;
